import React, { useEffect } from 'react';
import {
  Box,
  Text, Stack,
  Link,
  VStack, Input,
  Button, Image, SimpleGrid, RadioGroup, Radio,
  Center, Badge,
  Card, CardHeader, CardBody, Heading
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";
import { CloseIcon } from '@chakra-ui/icons'
import Checkout from './Checkout';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});  

function Collection() {
  const { appState, setAppState } = useAppStateStore();
     
  const removeFromCart = (book_id) => {
    setAppState({ ...appState, loading: book_id, });

    fetch(appState.endpoint + 'cart', {
      method : "DELETE",
      body   : JSON.stringify({
        id    : book_id,
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          let thisBook = {};
          const books  = appState.cart.books.reduce((prev, curr) => {
            if (curr.id === book_id) {
              thisBook = { ...curr, token: '', day_of_year: '', };
            }
            else {
              prev.push(curr);
            }
            
            return prev;
          }, []);
          
          const storeBooks = [ ...appState.books, thisBook, ];
          const cart = { ...appState.cart, books: books, parcel_id: '', shipment_id: '', chosenRate: {cost:0}, rates: [], checkout: false, };
          setAppState({ ...appState, books: storeBooks, cart: cart, loading: false, });
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('error');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });        


  };

  if (appState.cart.books.length) {
    const collection = appState.cart.books.map((b) => {
      return (
        <Card width='90%' variant='elevated' key={`c${b.id}`}>
          <CardHeader>
            <Heading size='md'>
              <Button colorScheme='orange' variant='link' size="lg" onClick={ () => {removeFromCart(b.id);} } isLoading={(appState.loading && appState.loading === b.id)}>
                <CloseIcon /> &nbsp; remove from cart &nbsp; 
              </Button><br />                
              {b.title}  
            </Heading>
          </CardHeader>
          <CardBody>
            
            <VStack spacing='2' align='left'>
              {
                b.image && 
                <Image src={b.image} alt="Book Cover" />
              }
              {
                b.authors &&
                <Text align='left' pt='0' fontSize='sm'>by {b.authors.join(', ')}</Text>
              }
              {
                b.edition && 
                <Text align='left' pt='0' fontSize='sm'>{b.edition}</Text>
              }
              <Text align='left' pt='0' fontSize='sm'>{b.binding}</Text>
              <Text align='left' pt='0' fontSize='sm'>Publisher: {b.publisher}</Text>
              <Text align='left' pt='0' fontSize='sm'>Published: {b.date_published}</Text>
              <Text align='left' pt='0' fontSize='sm'>Condition: {b.condition}</Text>
              {
                b.description && 
                <Text align='left' pt='0' fontSize='sm'>{b.description}</Text>
              }            
              <Text pt='0' fontSize='sm'>{USDollar.format(b.price.replace(/[^\d.]/, ''))}</Text>
            </VStack>
            
          </CardBody>
        </Card>      
      );
    });
    return collection;  
  }
  else {
    return (
      <Center>Nothing in your cart yet.</Center>
    );
  }
}

function ShippingChoice() {
  const { appState, setAppState } = useAppStateStore();
  
  const sortRates = (a, b) => {
    if (parseFloat(a.cost) < parseFloat(b.cost)) { 
      return -1; 
    }
    else if (parseFloat(a.cost) > parseFloat(b.cost)) { 
      return 1; 
    }
    else { 
      return 0; 
    }
  };

  const rates = appState.cart.rates.sort(sortRates);
  
  const setShipping = (evt) => {
    const id   = evt.target.value;
    const cart = { ...appState.cart, selectedShipping : id, };
    setAppState({ ...appState, cart: cart, });
  };

  return rates.map((r) => {
    return (
      <Radio value={r.id} style={{clear: 'both'}} onChange={setShipping} key={r.id}>
      ${r.cost} {r.by} {r.name}
      {
        r.days && <>: Est. days: {r.days}</>
      }
      {
        r.terms && <> ({r.terms})</>
      }
      </Radio> 
    );
  });  
}

function UpperSection() {
  const { appState, setAppState } = useAppStateStore();

  const costInfo = () => {
    const info = [`Subtotal: ${USDollar.format(appState.cart.subtotal)}`, ];
    if (parseFloat(appState.cart.chosenRate.cost)) {
      info.push(`Shipping: ${USDollar.format(parseFloat(appState.cart.chosenRate.cost))}`);
      const total = parseFloat(appState.cart.chosenRate.cost) + parseFloat(appState.cart.subtotal);
      info.push(`<strong>Total: ${USDollar.format(total)}</strong>`);
    }
    return info.join("<br />")
  };
    
  if (parseFloat(appState.cart.chosenRate.cost)) {
    const goCheckout = () => {
      setAppState({ ...appState, loading : true, });
      fetch(appState.endpoint + 'orders', {
        method : 'POST',
        body   : JSON.stringify({
          token       : appState.token, 
          ship_to     : appState.cart.shipTo,
          rate        : appState.cart.chosenRate,
          parcel_id   : appState.cart.parcel_id,
          shipment_id : appState.cart.shipment_id,
        })
      })
      .then((res) => {
        res.json().then((data) => {
          if ('OK' === data.status) {
            const cart = { ...appState.cart, checkout: true, };
            setAppState({ ...appState, cart : cart, loading: false, });          
          }
          else {
            setAppState({ ...appState, loading: false, message: 'error saving data'});
          }
        })
      })
      .catch((err) => {
        console.error(err);
        setAppState({ ...appState, loading: false, message: 'error saving data'});
      });    
    };
  
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='60%'
        pt='10'
        mt='10'
        pb='10'
        minH='100'
      >
        <SimpleGrid spacing={4} columns={{base: 1, md: 2}}>
          <Card width='100%' variant='elevated' key='costInfo'>
            <CardHeader>
              <Heading size='md'>
                <Text align='left' pt='0' fontSize='md'>Cart Cost Summary</Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text align='left' p='10' fontSize='md' dangerouslySetInnerHTML={{__html:costInfo()}} />
            </CardBody>
          </Card>
          
          <Card width='100%' variant='elevated' key='checkoutButton'>
            <CardBody>
              <Box
                display="flex"
                alignItems='bottom'
                justifyContent='center'
                width='100%'
                pt='10'
                mt='10'
                pb='10'
              >
                <Button colorScheme='blue' size='lg' onClick={goCheckout} isLoading={appState.loading} loadingText='Saving...'>Checkout</Button>
              </Box>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>
    );
  }
  else {
    const sectionWidth = appState.cart.rates.length ? '100%' : '60%';
  
    if (appState.cart.rates.length) {

      const setShipping = () => {
          
        const rate = appState.cart.rates.reduce((prev, curr) => {
            if (curr.id === appState.cart.selectedShipping) {
              return curr;
            }
            else {
              return prev;
            }
          }, {cost: 0});  
      
          const shipTo = { 
            ...appState.cart.shipTo, 
            loading    : false, 
            isValid    : true, 
            open       : false, 
            message    : '', 
          };            
          const cart = { ...appState.cart, chosenRate : rate, update: '', shipTo : shipTo, checkout: false, };
          setAppState({ ...appState, cart : cart, });
        };
          
      return (
        <Card width={sectionWidth} variant='outline'>
          <CardBody>
            <>
              <Box
                display="flex"
                alignItems='top'
                justifyContent='center'
                width='100%'
                pt='10'
                mt='10'
                pb='10'
                minH='100'
              >
                <RadioGroup name="shipChoice">   
                  <Stack spacing={2} direction='column'>
                    <ShippingChoice />
                  </Stack>
                </RadioGroup>
              </Box>
              <Box
                display="flex"
                alignItems='top'
                justifyContent='center'
                width='100%'
                pt='10'
                mt='10'
                pb='10'
                minH='100'
              >
                <Button colorScheme='blue' size='sm' onClick={setShipping}>Select Shipping &amp; Continue</Button>
              </Box>
            </>
          </CardBody>
        </Card>
      );
    }
    else {
      const openShipTo = () => {
        const shipTo = { ...appState.cart.shipTo, open: true, };
        const cart   = { ...appState.cart, shipTo : shipTo, checkout: false, };
        setAppState({ ...appState, cart: cart, });
      };
      
      return (
        <Card width={sectionWidth} variant='outline'>    
          <CardBody>
              <Text align='left' p='10' fontSize='md' dangerouslySetInnerHTML={{__html:costInfo()}} />
              {
                (!appState.cart.shipTo.open && !appState.cart.shipTo.isValid) &&
                <Link p='10' fontSize='md' color='blue' onClick={openShipTo}>Enter Shipping Address to calculate Shipping Costs</Link>                
              }
          </CardBody>
        </Card>  
      );
    }
  } 
}

function Cart() {
  const { appState, setAppState } = useAppStateStore();
  
  useEffect(() => {
    const subtotal = appState.cart.books.reduce((st, b) => {
      st += parseFloat(b.price);
      return st;
    }, 0.00);
    
    if (subtotal !== appState.cart.subtotal) {
      if (appState.cart.shipTo.isValid) {
        let parcel_id = '';
        let weight    = '';
      
        fetch(appState.endpoint + 'package', {
          method : "POST",
          body   : JSON.stringify(appState.cart)
        })
        .then((res) => {
          res.json().then((data) => {
            if ('OK' === data.status) {
              
              parcel_id = data.object_id;
              weight    = data.weight;
            
              const shipTo = { 
                ...appState.cart.shipTo, 
                loading    : true, 
                isValid    : true, 
                open       : true, 
                message    : '', 
                weight     : data.weight,
              };          
              const cart = { 
                ...appState.cart, 
                parcel_id    : data.object_id, 
                shipTo       : shipTo,
                update       : 'getting shipping costs...',
                subtotal     : subtotal, 
                chosenRate   : { cost: 0, },
                checkout     : false,
              };
              setAppState({ ...appState, cart: cart, });  
              
              fetch(appState.endpoint + 'shipment', {
                method : "POST",
                body   : JSON.stringify({
                  parcel_id : parcel_id,
                  ship_to   : appState.cart.shipTo.address_id,
                  weight    : weight,
                })
              })
              .then((res) => {
                res.json().then((data) => {
                  const shipTo = { 
                    ...appState.cart.shipTo, 
                    loading    : false, 
                    isValid    : true, 
                    open       : false, 
                    message    : '', 
                  };   
                         
                  const cart = { 
                    ...appState.cart, 
                    shipment_id : data.object_id, 
                    shipTo      : shipTo,
                    parcel_id   : parcel_id,
                    rates       : data.rates,
                    update      : '',       
                    subtotal    : subtotal,   
                    chosenRate  : { cost: 0, },  
                    checkout    : false,            
                  };
                  setAppState({ ...appState, cart: cart, });                          
                });
              })
              .catch((err) => {
                console.error(err);
                const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating shipment object', };
                const cart   = { ...appState.cart, shipTo: shipTo, update: '', subtotal: subtotal, };
                setAppState({ ...appState, cart: cart, });
              });                         
            }
            else {
              const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
              const cart   = { ...appState.cart, shipTo: shipTo, update: '', subtotal: subtotal, };
              setAppState({ ...appState, cart: cart, });
            }
          });
        })
        .catch((err) => {
          console.error(err);
          const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
          const cart   = { ...appState.cart, shipTo: shipTo, update: '',  };
          setAppState({ ...appState, cart: cart, });
        });            
      
      }
      else {
        const cart = { ...appState.cart, subtotal: subtotal, };
        setAppState({ ...appState, cart: cart, });    
      }    
    }    
    
  }, [appState.cart.books]);
  
  if (appState.cart.checkout) {
    return (<Checkout />);
  }
  else {
    const updateField = (evt) => {
      const shipTo = { ...appState.cart.shipTo, [evt.target.name]: evt.target.value, message: '', };
      const cart   = { ...appState.cart, shipTo: shipTo, };
      setAppState({ ...appState, cart: cart });
    };
      
    const validate = () => {      
      if (!(appState.cart.shipTo.street1 && appState.cart.shipTo.city && appState.cart.shipTo.state && appState.cart.shipTo.zip)) {
        const shipTo = { ...appState.cart.shipTo, message: "Please provide a complete mailing address", };
        const cart = { ...appState.cart, shipTo: shipTo, };
        setAppState({ ...appState, cart: cart });
        return false;
      }
      else if (!appState.cart.shipTo.email.match(/\w@\w/)) {
        const shipTo = { ...appState.cart.shipTo, message: "Please provide a deliverable email address", };
        const cart = { ...appState.cart, shipTo: shipTo, };
        setAppState({ ...appState, cart: cart });
        return false;
      }
    
      const shipTo = { ...appState.cart.shipTo, loading: true, message : '', };
      const cart   = { ...appState.cart, shipTo: shipTo, update : '', };
      setAppState({ ...appState, cart: cart, });
      
      let address_id = '';
      let parcel_id  = '';
      let weight     = 1000;
    
      fetch(appState.endpoint + 'address', {
        method : "POST",
        body   : JSON.stringify(appState.cart.shipTo)
      })
      .then((res) => {
        res.json().then((data) => {
          if (data.is_valid) {
          
            address_id = data.object_id;
            
            if (appState.cart.books.length) {
              const shipTo = { 
                ...appState.cart.shipTo, 
                loading    : true, 
                isValid    : true, 
                open       : true, 
                message    : '', 
                address_id : data.object_id,
              };          
              const cart   = { 
                ...appState.cart, 
                shipTo   : shipTo, 
                checkout : false,
                update   : 'address validated; creating package record...'
              };
              setAppState({ ...appState, cart: cart, });
                
              fetch(appState.endpoint + 'package', {
                method : "POST",
                body   : JSON.stringify(appState.cart)
              })
              .then((res) => {
                res.json().then((data) => {
                  if ('OK' === data.status) {
                    
                    parcel_id = data.object_id;
                    weight    = data.weight;
                  
                    const shipTo = { 
                      ...appState.cart.shipTo, 
                      loading    : true, 
                      isValid    : true, 
                      open       : true, 
                      message    : '', 
                      address_id : address_id,
                      weight     : data.weight,
                    };          
                    const cart = { 
                      ...appState.cart, 
                      parcel_id : data.object_id, 
                      shipTo    : shipTo,
                      checkout  : false,
                      update    : 'getting shipping costs...'
                    };
                    setAppState({ ...appState, cart: cart, });  
                    
                    fetch(appState.endpoint + 'shipment', {
                      method : "POST",
                      body   : JSON.stringify({
                        parcel_id : parcel_id,
                        ship_to   : address_id,
                        weight    : weight,
                      })
                    })
                    .then((res) => {
                      res.json().then((data) => {
                        const shipTo = { 
                          ...appState.cart.shipTo, 
                          loading    : false, 
                          isValid    : true, 
                          open       : false, 
                          message    : '', 
                          address_id : address_id,
                        };   
                               
                        const cart = { 
                          ...appState.cart, 
                          shipment_id : data.object_id, 
                          shipTo      : shipTo,
                          parcel_id   : parcel_id,
                          rates       : data.rates,
                          checkout    : false,
                          update      : '',                        
                        };
                        setAppState({ ...appState, cart: cart, });                          
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                      const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating shipment object', };
                      const cart   = { ...appState.cart, shipTo: shipTo, update: '', };
                      setAppState({ ...appState, cart: cart, });
                    });                         
                  }
                  else {
                    const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
                    const cart   = { ...appState.cart, shipTo: shipTo, update: '', };
                    setAppState({ ...appState, cart: cart, });
                  }
                });
              })
              .catch((err) => {
                console.error(err);
                const shipTo = { ...appState.cart.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
                const cart   = { ...appState.cart, shipTo: shipTo, update: '', };
                setAppState({ ...appState, cart: cart, });
              });        
  
            }
            else {
              const shipTo = { 
                ...appState.cart.shipTo, 
                loading   : false, 
                isValid   : true, 
                open      : false, 
                message   : '', 
                object_id : data.object_id
              };          
              const cart   = { 
                ...appState.cart, 
                shipTo   : shipTo, 
                checkout : false,
                update   : 'address validated; no book in cart yet'
              };
              setAppState({ ...appState, cart: cart, });          
            }
          }
          else {
            const shipTo = { ...appState.cart.shipTo, loading: false, isValid: false, message: data.message, };
            const cart   = { ...appState.cart, shipTo: shipTo, };
            setAppState({ ...appState, cart: cart, });
          }
        })
      })
      .catch((err) => {
        console.error(err);
        const shipTo = { ...appState.cart.shipTo, loading: false, isValid: false, };
        const cart   = { ...appState.cart, shipTo: shipTo, };
        setAppState({ ...appState, cart: cart, });
        alert('error');
      });        
    };
      
    return (
        <>  
          <Box
            display="flex"
            alignItems='top'
            justifyContent='center'
            width='100%'
            pt='5'
            mt='0'
            pb='5'
            minH='100'
            bgGradient="linear(to-b, white, #cccccc)"
          >
            <UpperSection />
          </Box>
          {
            appState.cart.shipTo.open && 
            <Box
              display="flex"
              alignItems='top'
              justifyContent='center'
              width='100%'
              pt='50'
              mt='0'
              pb='50'
              minH='100'
              bgGradient="linear(to-b, white, #cccccc)"
            >
  
              <VStack spacing={4} align='stretch'>
                <Input variant='outline' placeholder='Ship to Name' name='name' value={appState.cart.shipTo.name} onChange={updateField} />
                <Input variant='outline' placeholder='Mailing Address' name='street1' value={appState.cart.shipTo.street1} onChange={updateField} />
                <Input variant='outline' placeholder='Address Line 2 (optional)' name='street2' value={appState.cart.shipTo.street2} onChange={updateField} />
                <Input variant='outline' placeholder='Town' name='city' value={appState.cart.shipTo.city} onChange={updateField} />
                <Input variant='outline' placeholder='State (e.g. NC)' name='state' value={appState.cart.shipTo.state} onChange={updateField} />
                <Input variant='outline' placeholder='Zip Code' name='zip' value={appState.cart.shipTo.zip} onChange={updateField} />
                <Input variant='outline' placeholder='Email' name='email' value={appState.cart.shipTo.email} onChange={updateField} />
                {
                  appState.cart.shipTo.message &&
                  <Badge colorScheme='red'>{appState.cart.shipTo.message}</Badge>
                }
                {
                  appState.cart.update &&
                  <Badge colorScheme='blue'>{appState.cart.update}</Badge>
                }
                <Button colorScheme='blue' size='lg' onClick={validate} isLoading={appState.cart.shipTo.loading} loadingText='Submitting'>Continue</Button>
              </VStack>
            
            </Box>
          }
          <Box
            display="flex"
            alignItems='top'
            justifyContent='center'
            width='100%'
            pt='50'
            mt='0'
            pl='10'
            pb='50'
            minH='1000'
            bgGradient="linear(to-b, white, #cccccc)"
          >
            <VStack spacing={4} align='stretch'>
              <SimpleGrid spacing={4} columns={{base: 1, md: 2, lg: 3, xl: 4}}>
                <Collection />
              </SimpleGrid>
            </VStack>
          </Box>
        </>
    ); 
  }  
}

export default Cart;