import React from 'react';
import {
  ChakraProvider, Wrap, Button,
  Box,
  theme,
} from '@chakra-ui/react';
import AppStateProvider from "./AppStateProvider";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Home from './Home';
import Books from './Books';
import Donate from './Donate';
import Cart from './Cart';
import Payment from './Payment';

function Main() {
  const navigate = useNavigate();
  
  const email = () => {
    window.location.href = 'mailto:revnato@sacredfireuu.org';
    return true;
  };
  
  return (
    <Routes>
      <Route path='/' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/Banned_Books_Display.png')"
          bgPosition='center center'
          bgSize='cover'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='purple' size='lg' outline='6px solid black'>
              Home
            </Button>
            <Button colorScheme='cyan' size='lg' outline='6px solid black' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='cyan' size='lg' outline='6px solid black' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='cyan' size='lg' outline='6px solid black' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='cyan' size='lg' outline='6px solid black' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
      
        <Home />
        </>
      } />
  
      <Route path='/books' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/Banned_Books_Display.png')"
          bgPosition='center center'
          bgSize='cover'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='purple' outline='6px solid black' size='lg'>
              Shop for Books
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Books />
        </>
      } />
  
      <Route path='/donate' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/Banned_Books_Display.png')"
          bgPosition='center center'
          bgSize='cover'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='purple' outline='6px solid black' size='lg'>
              How to Donate
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Donate />
        </>
      } /> 

      <Route path='/cart' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/Banned_Books_Display.png')"
          bgPosition='center center'
          bgSize='cover'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='purple' outline='6px solid black' size='lg'>
              Cart
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Cart />
        </>
      } /> 

      <Route path='/payment' element={
        <>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          bgImage="url('/Banned_Books_Display.png')"
          bgPosition='center center'
          bgSize='cover'
          bgColor="black"
          bgRepeat='no-repeat'
          padding="20"
        >
           <Wrap spacing={4}>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/')}>
              Home
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/books')}>
              Shop for Books
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/donate')}>
              How to Donate
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={() => navigate('/cart')}>
              Cart
            </Button>
            <Button colorScheme='cyan' outline='6px solid black' size='lg' onClick={email}>
              Contact Us
            </Button>
          </Wrap>        
         
        </Box>
        <Payment />
        </>
      } /> 
    </Routes>
  );  
}

function App() {
  return (
    <AppStateProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </ChakraProvider>
    </AppStateProvider>
  );
}

export default App;
