import React from 'react';
import {
  Box,
  Text,
  Link,
  Card, CardHeader, CardBody, Heading, Stack, StackDivider
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'

function Home() {
  return (
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pt='50'
          mt='0'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
        
          <Card  width={{base: '90%', lg: "50%"}}>
            <CardHeader>
              <Heading size='lg'>Banned Book Bundles</Heading>
              <Heading size='sm'>by The Minister Book Exchange</Heading>
            </CardHeader>
          
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Text pt='2' fontSize='md'>
                    The rise in the amount of banned books has never been so rampant.
                    School districts across the country are held hostage by conservative
                    groups bent on depriving our children of literature through the use of
                    censoring, thereby controlling what books are available to our
                    families.  
                  </Text>
                  
                  <Text pt='2' fontSize='md'>
                    Banned Book Bundles is a project of <Link href='https://www.sacredfireuu.org' isExternal>
                    Sacred Fire Unitarian Universalist <ExternalLinkIcon mx='2px' /></Link>, 
                    a justice-seeking ministry which seeks to build learning
                    communities and access to vital literature. Please peruse our current
                    offerings&mdash;which will grow with time and capacity. The more books you
                    add to your &lsquo;bundle,&rsquo; the larger the discount amount.
                  </Text>
                  
                  <Text pt='2' fontSize='md'>
                    Please join us in this project by donating to our 501(3) non-profit.
                    These funds assist in our administrative costs and serve to subsidize
                    the books, making access more possible to families across the country.                    
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>        
        </Box>
  );
}

export default Home;