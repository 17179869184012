import React, { useEffect } from 'react';
import {
  Box, 
  Text,
  VStack, 
  Button, Spinner, Image, SimpleGrid,
  Center, 
  Card, CardHeader, CardBody, Heading
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";
import { PlusSquareIcon } from '@chakra-ui/icons'

function Missing() {
  const { appState } = useAppStateStore();
  
  const simpleHash = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
    }
    // Convert to 32bit unsigned integer in base 36 and pad with "0" to ensure length is 7.
    return (hash >>> 0).toString(36).padStart(7, '0');
  };  

  if (appState.missing.length) {
    return appState.missing.map((b) => {
      return (
        <Card width='90%' variant='elevated' key={simpleHash(b)}>
          <CardHeader>
            <Heading size='md'>Recommended reading, but not currently in stock:</Heading>
          </CardHeader>
          <CardBody>
            <Text>{b}</Text>
          </CardBody>
        </Card>      
      );
    });  
  }
  else {
    return [];
  }

}

function Collection() {
  const { appState, setAppState } = useAppStateStore();
  
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  
  
  const addToCart = (book_id) => {
    setAppState({ ...appState, loading: book_id, });
    
    fetch(appState.endpoint + 'cart', {
      method : "POST",
      body   : JSON.stringify({
        id    : book_id,
        token : appState.token,
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          let thisBook = {};
          const books  = appState.books.reduce((prev, curr) => {
            if (curr.id === book_id) {
              thisBook = curr;
            }
            else {
              prev.push(curr);
            }
            
            return prev;
          }, []);   
          
          const display = appState.display.reduce((prev, curr) => {
            if (curr.id === book_id) {
              // pass
            }
            else {
              prev.push(curr);
            }
            
            return prev;
          
          }, []);
          
          const cartBooks = [ ...appState.cart.books, thisBook, ];
          const cart = { ...appState.cart, books: cartBooks, chosenRate: {cost:0}, rates: [], };
          setAppState({ ...appState, books: books, cart: cart, loading: false, display: display, });                
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('error');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });        
  };

  if (appState.books.length) {
    const collection = appState.display.map((b) => {
      return (
        <Card width='90%' variant='elevated' key={b.id}>
          <CardHeader>
            <Heading size='md'>
              <Button colorScheme='orange' variant='link' size="lg" onClick={ () => {addToCart(b.id);} } isLoading={(appState.loading && appState.loading === b.id)}>
                <PlusSquareIcon /> &nbsp; add to cart &nbsp; 
              </Button><br />                
              {b.title}  
            </Heading>
          </CardHeader>
          <CardBody>
            
            <VStack spacing='2' align='left'>
              {
                b.image && 
                <Image src={b.image} alt="Book Cover" />
              }
              {
                b.authors &&
                <Text align='left' pt='0' fontSize='sm'>by {b.authors.join(', ')}</Text>
              }
              {
                b.edition && 
                <Text align='left' pt='0' fontSize='sm'>{b.edition}</Text>
              }
              <Text align='left' pt='0' fontSize='sm'>{b.binding}</Text>
              <Text align='left' pt='0' fontSize='sm'>Publisher: {b.publisher}</Text>
              <Text align='left' pt='0' fontSize='sm'>Published: {b.date_published}</Text>
              <Text align='left' pt='0' fontSize='sm'>Condition: {b.condition}</Text>
              {
                b.description && 
                <Text align='left' pt='0' fontSize='sm'>{b.description}</Text>
              }            
              <Text pt='0' fontSize='sm'>{USDollar.format(b.price.replace(/[^\d.]/, ''))}</Text>
            </VStack>
            
          </CardBody>
        </Card>      
      );
    });
    return collection;  
  }
  else {
    return (
      <Center>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Center>
    );
  }
}

function Books() {
  const { appState, setAppState } = useAppStateStore();

  useEffect(() => {
    fetch(appState.endpoint + 'books', {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        
        data.collection.sort((a,b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          else {
            return 0;
          }
        });
        
        let missing = [
          'Catcher in the Rye by J.D. Salinger',
          'Bluest Eye by Toni Morrison',         
          '1984 by George Orwell',
          'The Color Purple by Alice Walker',
          'I Know Why the Caged Bird Sings by Maya Angelou',
          'The Handmaid’s Tale by Margaret Atwood',
          'Beyond Magenta by Susan Kuklin',
          'The Hate U Give bu Angie Thomas',
          'The Absolutely True Diary of a Part-Time Indian by Sherman Alexie',
          'The Kite Runner by Khaled Hosserini',
          'And Tango Makes Three by Peter Parvel & Justin Richardson',
          'Drama – Raina Telgemeier'
        ];

        const display = data.collection.reduce((list, curr) => {
          if (list.length) {
            const prev = list[(list.length - 1)];
            
            if ((prev.isbn && prev.isbn === curr.isbn) || (prev.title && prev.authors && prev.title === curr.title && prev.authors === curr.authors)) {
              // skip
            }
            else {
              const authstring = curr.authors.join(' ');
              
              if (curr.title.match(/^Catcher in the Rye/i) && authstring.match(/Salinger$/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^Catcher/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^Bluest Eye/i) && authstring.match(/^Toni Morrison/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^Bluest/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^1984/i) && authstring.match(/^George Orwell/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^1984/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^The Color Purple/i) && authstring.match(/^Alice Walker/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^The Color/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^I Know Why the Caged Bird Sings/i) && authstring.match(/^Maya Angelou/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^I Know/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^The Handmaid/i) && authstring.match(/^Margaret Atwood/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^I Know/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^Beyond Magenta/i) && authstring.match(/^Susan Kuklin/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^Beyond/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^The Hate/i) && authstring.match(/^Angie Thomas/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^The Hate/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^The Absolutely True Diary of a Part/i) && authstring.match(/^Sherman Alexie/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^The Absolutely/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^The Kite Runner/i) && authstring.match(/^Khaled Hosserini/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^The Kite/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^And Tango Makes Three/i) && authstring.match(/^Peter Parvel/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^And/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;
              }
              else if (curr.title.match(/^Drama/i) && authstring.match(/^Raina Telgemeier/i)) {
                list.push(curr);  
                const m = missing.reduce((p, c) => {
                  if (c.match(/^Drama/i)) { /* pass */ }
                  else { p.push(c); }
                  return p;
                }, []);
                missing = m;              
              }              
            }            
          }
          
          return list;
        }, []);
      
        const cart = { ...appState.cart, books: data.cart, };
        setAppState({ 
          ...appState, 
          books   : data.collection, 
          display : display,
          dispAll : display,
          missing : missing,
          token   : data.token, 
          cart    : cart, 
        });
      })
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });    
  }, []);

  return (
    <>
        <Box
          display="flex"
          alignItems='top'
          justifyContent='center'
          width='100%'
          height='100%'
          pt='50'
          mt='0'
          pl='10'
          pb='50'
          minH='1000'
          bgGradient="linear(to-b, white, #cccccc)"
        >
        
          <VStack spacing={4} align='stretch'>
            <SimpleGrid spacing={4} columns={{base: 1, md: 2, lg: 3, xl: 4}}>
              <Collection />
              <Missing />
            </SimpleGrid>
          </VStack>
        </Box>
      </>
  );
}

export default Books;